import { MACOSNAME, WINDOWSOSNAME, LINUXOSNAME } from "../constants/support.constants";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const validateEmail = (email) => {
    const re = /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
    return re.test(String(email).toLowerCase());
};
export const getFormattedPhoneNumber = (input) => {
    input = input.replace(/\D/g, '');
    // Format the phone number  
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export const mapUnformattedCursorPosition = (input, cursorPosition) => {
    let nonDidgitCount = 0;
    for (let i = 0; i < cursorPosition; i++) {
        if (/\D/.test(input[i])) {
            nonDidgitCount++
        }
    }
    const unformattedCursorPosition = cursorPosition - nonDidgitCount;
    return unformattedCursorPosition;
}
export const mapformattedCursorPosition = (cursorPosition) => {
    const charsToCount = ['(',')', ' ', '-'];
    const formattedPhoneNumber = "(xxx) xxx-xxx"
    let charCount = 0;
    let numberCount = 0;
    for (let i = 0; i < formattedPhoneNumber.length; i++) {
        if (numberCount > cursorPosition) {
            break;
        }
        if (charsToCount.includes(formattedPhoneNumber[i])) {
            charCount++
        } else {
            numberCount++
        }
    }
    return charCount + cursorPosition;

}

const debounce = (func, delayTime = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, delayTime);
    };
};

const prepareQueryParams = (obj) => {
    const keyValueData = [];
    for (const key in obj) {
        keyValueData.push(`${key}=${encodeURIComponent(obj[key])}`)
    }
    return keyValueData.join("&");
}

const truncateSpecialChar = (input) => {
    input = input?.replace(/[^0-9]/g, '');
    // Remove the special char
    return input?.slice(0, 10);
}

const removeFormattedValues = (inputStr) => {
    if (inputStr.includes('@')) {
        // If it's likely an email, return it as is
        return inputStr;
    } else {
        // For other inputs like phone numbers, remove all non-digit characters
        return inputStr.replace(/[^0-9]/g, '');
    }
}


const isNumber = (value) => /^\d+$/.test(value);
const isAlphaNumeric = (value) => /^[A-Za-z0-9]*$/.test(value);
const isIncludesAtChar = (value) => value.includes('@');
const sliceText = (value, sliceStartIndex, sliceEndIndex) => value.slice(sliceStartIndex, sliceEndIndex);

const isValidMobile = (inputValue) => {
    const digitsOnly = truncateSpecialChar(inputValue);
    return digitsOnly.length === 10 && isNumber(digitsOnly);
};

const copyTextToClipboard = (text) => {
    if (!('clipboard' in navigator)) {
        const copyTextContainer = document.createElement('textarea');
        document.body.appendChild(copyTextContainer);
        copyTextContainer.value = text;
        copyTextContainer.select();
        document.execCommand('copy');
        document.body.removeChild(copyTextContainer);
    } else {
        navigator.clipboard.writeText(text);
    }
};

const parseUserAgent = (userAgent) => {
    const userAgentValue = userAgent?.userAgent;
    if (!userAgent || !userAgent.browserVersion || !userAgentValue) {
        return {
            browserName: '',
            browserVersion: '',
            osName: '',
            osVersion: ''
        };
    }
    const [browserName, browserVersion] = userAgent.browserVersion.split('/');
    const userAgentData = userAgent.userAgent;
    const osInfoMatch = userAgentData ? userAgentData.match(/\(([^()]+)\)/) : null;
    const osDetails = osInfoMatch ? osInfoMatch[1] : "";
    const osParts = osDetails.split(';').map(part => part.trim());
    const [,osPart,] = osParts;
    let osName = (osDetails ? osDetails.split('; ').map(part => (part.split(' ')[1] || '')) : ["", ""])[1];
    const osVersion = (osName.toLowerCase() === 'iphone' ? (osPart.split('OS ')[1].split(' ')[0].replace(/\_/g, ".")) : (osPart.split(' ').pop() || ""));

    if (!osName && userAgentValue) {
        if (userAgentValue.indexOf(MACOSNAME) != -1) {
            osName = MACOSNAME;
        }
        if (userAgentValue.indexOf(WINDOWSOSNAME) != -1) {
            osName = WINDOWSOSNAME;
        }
        if (userAgentValue.indexOf(LINUXOSNAME) != -1) {
            osName = LINUXOSNAME;
        }
    }
    return {
        browserName,
        browserVersion,
        osName: osName || "",
        osVersion: osVersion || "",
        configResponse: userAgent || ''
    }

};

const sliceTextWithoutSplChar = (value, start = 0, end = 12)  => {
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    return cleanedValue.slice(start, end);
  }

export {
    delay,
    validateEmail,
    debounce,
    prepareQueryParams,
    truncateSpecialChar,
    isNumber,
    isValidMobile,
    copyTextToClipboard,
    parseUserAgent,
    isAlphaNumeric,
    isIncludesAtChar,
    sliceText,
    sliceTextWithoutSplChar,
    removeFormattedValues
}
